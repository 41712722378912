import React, { useState, useEffect, useRef } from "react";
import { Container, Button } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { ENDPOINT } from "../../constants";

export const AudioExerciseButton = ({
  jwtToken,
  onTokenChange,
  selectedExercise,
}) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [micPermission, setMicPermission] = useState("pending");
  const [sessionId, setSessionId] = useState(null);
  const [lastExercise, setLastExercise] = useState(null);
  const audioRef = useRef(null);
  // Add a state variable for the audio source URL
  const [audioSrc, setAudioSrc] = useState(null);
  const [hasPlayed, setHasPlayed] = useState(false);

  const mediaRecorderRef = useRef(null); // Ref for MediaRecorder instance
  const releaseMediaResources = () => {
    if (mediaRecorderRef.current) {
      if (mediaRecorderRef.current.state !== "inactive") {
        mediaRecorderRef.current.stop();
      }
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
      mediaRecorderRef.current = null;
    }
  };
  useEffect(() => {
    // This effect is dedicated to playing the audio when audioSrc changes.
    const playAudio = async () => {
      //if (audioSrc && audioRef.current && audioSrc != audioRef.current.src && !hasPlayed) {
      if (audioSrc && audioRef.current) {
        audioRef.current.src = audioSrc; // Update the source to the new URL
        try {
          await audioRef.current.play();
          setHasPlayed(true);
        } catch (e) {
          console.error("Playback failed:", e);
        }
      }
    };

    playAudio();
  }, [audioSrc, hasPlayed]);

  useEffect(() => {
    const handleWindowFocus = () => {
      if (isButtonActive) {
        setIsButtonActive(false);
        stopRecording();
      }
    };
    window.addEventListener("focus", handleWindowFocus);

    checkMicPermission();

    const initAudioAutoplay = () => {
      if (audioRef.current) audioRef.current.load();
      document.removeEventListener("click", initAudioAutoplay);
    };

    document.addEventListener("click", initAudioAutoplay);

    const getToken = async () => {
      try {
        if (jwtToken === "asdf") {
          jwtToken = await getAccessTokenSilently();
          onTokenChange(jwtToken);
          console.log(jwtToken);
          // Upsert user to users table
          const response = await fetch(`${ENDPOINT}/user`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${jwtToken}`,
            },
          });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        return; // Return early if there's an error getting the token
      }
    };

    getToken();
    return () => {
      document.removeEventListener("click", initAudioAutoplay);
      window.removeEventListener("focus", handleWindowFocus);
    };
  }, [isButtonActive, jwtToken]);

  const startSessionIfNeeded = async () => {
    if (!sessionId) {
      console.log("Starting new session for:", selectedExercise);
      // Reset existing session if exercise has changed
      //setSessionId(null);
      //setLastExercise(selectedExercise); // Update lastExercise to the current one

      // Logic to start a new session...
      try {
        const response = await fetch(`${ENDPOINT}/exercise/session/`, {
          method: "POST",
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            exerciseName: selectedExercise,
          }),
        });
        console.log("Made the request");
        if (!response.ok) {
          throw new Error("Failed to start new exercise session");
        }
        const data = await response.json();
        console.log("session data:");
        console.log(JSON.stringify(data));
        let body = data.body;
        console.log(`New session: ${body.sessionId}`);
        setSessionId(body.sessionId); // Update session ID state
        return body.sessionId;
      } catch (error) {
        console.error("Error starting new session:", error);
      }
    }
  };

  const getPreSignedUploadLink = async () => {
    let newSessionId = null;
    if (sessionId == null) {
      console.log("Session ID is null, starting new session");
      newSessionId = await startSessionIfNeeded();
      console.log("New session ID?");
      console.log(newSessionId);
    }

    console.log("SessionId:", sessionId);
    console.log("NewSessionId:", newSessionId);
    let sessionInUse = sessionId ?? newSessionId;
    console.log("Session in use:", sessionInUse);
    const presignedUrlResponse = await fetch(`${ENDPOINT}/audio/upload`, {
      method: "POST",
      headers: {
        Authorization: `${jwtToken}`, // Assuming JWT token is needed for auth
      },
      body: JSON.stringify({
        exerciseName: selectedExercise,
        sessionId: sessionInUse,
      }),
    });

    if (!presignedUrlResponse.ok) {
      throw new Error("Failed to get presigned URL");
    }
    let responseData = await presignedUrlResponse.json();

    let uploadData = responseData.body.body;
    //presignedUrl, audioObjectKey, exerciseId
    console.log(`purl ${uploadData.presignedUrl}`);

    return uploadData;
  };

  const uploadAudio = async (
    audioBlob,
    preSignedUrl,
    audioObjectKey,
    exerciseId
  ) => {
    try {
      console.log("uploadingAudio");
      const formData = new FormData();
      Object.entries(preSignedUrl.fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("file", audioBlob);
      console.log(`The upload url: ${preSignedUrl.url}`);

      const s3Response = await fetch(preSignedUrl.url, {
        method: "POST",
        body: formData, // FormData will set the correct content type header
      });

      console.log("s3 response:");
      console.log(s3Response);
      if (!s3Response.ok) {
        throw new Error("Failed to upload audio to S3");
      }

      const transcribeResponse = await fetch(
        `${ENDPOINT}/transcription/audio/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Ensure you're setting the correct content type
            Authorization: `${jwtToken}`,
          },
          body: JSON.stringify({
            exerciseName: selectedExercise,
            exerciseId,
            audioObjectKey,
            sessionId,
          }),
        }
      );
      if (transcribeResponse.ok) {
        console.log("Getting the transcribe response");

        // Read and parse the ReadableStream as JSON
        const responseData = await transcribeResponse.json();
        console.log(responseData);
        console.log(JSON.stringify(responseData));
        //console.log("transcribe response data");
        //console.log(responseData);
        const audioDownloadLink = responseData.audioDownloadLink;
        console.log("Audio download link:");
        console.log(audioDownloadLink);

        setAudioSrc(audioDownloadLink);
        setHasPlayed(false);
      } else {
        console.log(
          "Failed to fetch:",
          transcribeResponse.status,
          transcribeResponse.statusText
        );
        const errorBody = await transcribeResponse.text(); // Handle error case
        console.log("Error response body:", errorBody);
      }

      // Optionally, set state or perform additional actions after successful upload
    } catch (error) {
      console.error("Error during the upload process:", error);
    }
  };

  const checkMicPermission = async () => {
    if (!navigator.permissions) {
      console.warn("Browser does not support navigator.permissions");
      return;
    }
    try {
      const result = await navigator.permissions.query({ name: "microphone" });
      setMicPermission(result.state);
      result.onchange = () => setMicPermission(result.state);
    } catch (error) {
      console.error("Error checking microphone permission:", error);
    }
  };

  const handlePress = (e) => {
    e.preventDefault();
    setIsButtonActive(true);

    if (micPermission !== "granted") {
      console.log("Requesting microphone permission");
      requestMicPermission();
    } else {
      startRecording();
    }
  };

  const handleRelease = (e) => {
    e.preventDefault();
    if (isButtonActive) {
      setIsButtonActive(false);
      console.log("Button released, stopping recording...");
      stopRecording();
    }
  };

  const requestMicPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log("Microphone permission granted");
      setMicPermission("granted");
      stream.getTracks().forEach((track) => track.stop());
      if (isButtonActive) {
        startRecording();
      }
    } catch (error) {
      setMicPermission("denied");
      console.error("Microphone access was denied.", error);
    }
  };

  const startRecording = async () => {
    console.log("Attempting to start recording...");
    await releaseMediaResources();

    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      console.warn("A recording is already in progress.");
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log("Stream obtained, initializing MediaRecorder...");
      mediaRecorderRef.current = new MediaRecorder(stream);
      let audioChunks = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        console.log("Data available from MediaRecorder...");
        console.log(`Data chunk size: ${event.data.size}`);
        audioChunks.push(event.data);
      };

      mediaRecorderRef.current.start();

      const { presignedUrl, audioObjectKey, exerciseId } =
        await getPreSignedUploadLink();
      console.log("Presigned URL:", presignedUrl);
      console.log("Audio Object Key:", audioObjectKey);
      console.log("Exercise ID:", exerciseId);
      mediaRecorderRef.current.onstop = () => {
        console.log("MediaRecorder stopped, creating audio blob...");
        console.log("video/mp4");
        console.log(`Total audio chunks: ${audioChunks.length}`);
        const audioBlob = new Blob(audioChunks, { type: "video/mp4" });
        console.log(`Created blob size: ${audioBlob.size}`);
        uploadAudio(audioBlob, presignedUrl, audioObjectKey, exerciseId);
      };
    } catch (error) {
      console.error(
        "Error accessing media devices or starting MediaRecorder:",
        error
      );
    }
  };

  const stopRecording = () => {
    console.log("Attempting to stop recording...");
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      console.log("Stopping MediaRecorder...");
      mediaRecorderRef.current.stop();
    } else {
      console.error("MediaRecorder not initialized or already inactive.");
    }
  };

  return (
    <Container className="mb-5 text-center">
      <Button
        color={isButtonActive ? "danger" : "primary"}
        onMouseDown={handlePress}
        onMouseUp={handleRelease}
        onMouseLeave={handleRelease}
        onTouchStart={handlePress}
        onTouchEnd={handleRelease}
      >
        {isButtonActive ? "Recording..." : "Push to Talk"}
      </Button>

      <div>
        <audio src={audioSrc} ref={audioRef} hidden playsInline />
      </div>
    </Container>
  );
};

export default withAuthenticationRequired(AudioExerciseButton, {
  onRedirecting: () => <Loading />,
});
