import React from "react";
import { Container, Row, Col } from "reactstrap";
import VideoPlayer from "../components/VideoPlayer";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

export const TrainingHome = () => {
  const { user } = useAuth0();

  return (
    <Container className="mb-5">
      <Row>
        <VideoPlayer videoUrl="https://dev.theflirtgenius.com/videos/lesson1.mp4"/>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(TrainingHome, {
  onRedirecting: () => <Loading />,
});
