import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardText,
  Button,
} from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import history from "../../utils/history";
import { AudioExerciseButton } from "../components/AudioInteraction";

const nextPage = () => {
  history.push("/exercises/weave/learn");
};

const previousPage = () => {
  history.push("/exercises/thread/learn");
};

export const ConnectedThreadsPage = ({ jwtToken, handleTokenChange }) => {
  const buttonStyle = { width: "200px" };
  return (
    <Container className="mb-5">
      <Row>
        <Col md="8">
          <h1 className="mb-4">Exercise 3: Connected Threads</h1>
        </Col>
      </Row>

      <Card>
        <CardBody>
          <Row>
            <Col md="4">
              <AudioExerciseButton
                selectedExercise="connectedthreads"
                jwtToken={jwtToken}
                onTokenChange={handleTokenChange}
              />
              <CardImg
                top
                style={{ width: "100%", maxWidth: "450px" }}
                src="https://s3.us-east-2.amazonaws.com/app.broadlysocial.com/media/images/mia.webp"
                alt="Conversation Threading"
              />
            </Col>
            <Col md="8">
              <CardText>
                <strong>Description</strong>
                <br />
                Building on the previous exercise, this one adds a layer of
                cohesion to the conversation. The aim is to acknowledge what
                your partner says with a brief comment or anecdote before
                steering the conversation in a new direction. This simulates
                more natural conversational dynamics.
                <br /> <br />
                <strong>Instructions</strong>
                <ul>
                  <li>
                    Acknowledge and Comment: Listen to your partner and
                    acknowledge their statement. Add a brief comment or personal
                    anecdote.
                  </li>
                  <li>
                    Find Inspiration: Use a word or idea from your partner's
                    last statement as a springboard for a new topic.
                  </li>
                  <li>
                    Shift Gently: Introduce a new, but loosely connected topic
                    based on the previous exchange.
                  </li>
                </ul>
                <strong>Example:</strong>
                <ul>
                  <li>
                    User: "I just finished organizing my workspace at home."
                  </li>
                  <li>
                    Mia: "That must feel really nice! It's made me think about
                    adding some greenery to my own desk area."
                  </li>
                  <li>
                    User: "Adding plants sounds like a great idea. That reminds
                    me, I heard that the garden nursery is having a big sale."
                  </li>
                  <li>
                    Mia: "That's great! Speaking of great ideas, I
                    was thinking about going for a bike ride this weekend."
                  </li>
                </ul>
                Participants continue to alternate, ensuring a seamless flow of
                unrelated topics.
              </CardText>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Row className="mt-3">
        <Col xs="6" className="d-flex justify-content-end pr-2">
          <Button style={buttonStyle} color="primary" onClick={previousPage}>
            Lesson 2: Threading
          </Button>
        </Col>
        <Col xs="6" className="d-flex justify-content-start pl-2">
          <Button style={buttonStyle} color="primary" onClick={nextPage}>
            Lesson 4: Weaving Conversations
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(ConnectedThreadsPage, {
  onRedirecting: () => <Loading />,
});
