import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardText,
} from "reactstrap";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { AudioExerciseButton } from "../components/AudioInteraction";

export const SandboxPage = ({ jwtToken, handleTokenChange }) => {
  return (
    <Container className="mb-5">
      <Row>
        {/* Full width on xs screens, 8 columns on md screens and above */}
        <Col xs="12" md="8">
          <h1 className="mb-4">Lesson 1: Getting Started</h1>
        </Col>
      </Row>

      <Card>
        <CardBody style={{ padding: '15px' }}>
          <Row>
            {/* Half width on sm screens, 4 columns on md screens and above */}
            {/* Full width on xs screens, half width on sm screens, 8 columns on md screens and above */}
            <Col xs="12" sm="6" md="8">
              <CardText style={{ fontSize: '1rem', lineHeight: '1.5' }}>
                This is our first lesson - introducing yourself. To get started,
                let's cover how our practice conversations actually work.
                <br />
                <br />
                Below you'll see a button that reads, "Push to Talk".
                <br />
                <br />
                When you hold down the button, it's your turn to talk.
                <br />
                <br />
                Once you release the button, I'll respond with what I have to
                say.
                <br />
                <br />
                Let's try it now with our first drill, which is just a basic
                introduction.
                <br />
                <br />
                You'll hold down the button, say hello and just introduce
                yourself.
                <br />
                <br />
                <strong>Exercise - Getting Started:</strong>
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;You: Hi, I'm [name]. How's it going?
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;Mia: [Brief response]
              </CardText>
            </Col>
          </Row>
          
          <br />
          <br />
        </CardBody>
      </Card>
      <AudioExerciseButton
            selectedExercise="getting-started"
            jwtToken={jwtToken}
            onTokenChange={handleTokenChange}
          />
    </Container>
  );
};

export default withAuthenticationRequired(SandboxPage, {
  onRedirecting: () => <Loading />,
});
