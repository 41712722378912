import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import { ENDPOINT } from "../../constants";
import './CheckoutFormStyles.css'

const CheckoutForm = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setLoading(true);

    // todo : Update for environment, update the amount, add a form box for the amount, style the stripe form
    let res = await axios.post(`${ENDPOINT}/stripe/payment`, { amount: 1099 });
    let data = res["data"];
    console.log(data);
    let clientSecret = data["clientSecret"];
    console.log("the secret2");
    console.log(clientSecret);
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });
    console.log(data);
    if (result.error) {
      setError(result.error.message);
    } else {
      console.log("Payment successful");
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className="checkout-form">
      <div className="input-container">
        <label className="label">Payment Details</label>
        <CardElement className="card-element" />
      </div>
      {error && <div className="error">{error}</div>}
      <button type="submit" disabled={!stripe || loading} className="pay-button">
        {loading ? 'Loading...' : 'Pay'}
      </button>
    </form>
  );
};

export default CheckoutForm;
