import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardText,
  Button,
} from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import history from "../../utils/history";
import { AudioExerciseButton } from "../components/AudioInteraction";
const nextPage = () => {
  history.push("/exercises/connectedthread/learn");
};

const previousPage = () => {
  history.push("/exercises/start/learn");
};
export const ThreadingPage = ({ jwtToken, handleTokenChange }) => {
  const buttonStyle = { width: "200px" };
  return (
    <Container className="mb-5">
      <Row>
        <Col md="8">
          <h1 className="mb-4">Exercise 2: Endless Ideas - Threading</h1>
        </Col>
      </Row>

      <Card>
        <CardBody>
          <Row>
            <Col md="4">
              <AudioExerciseButton
                selectedExercise="threading"
                jwtToken={jwtToken}
                onTokenChange={handleTokenChange}
              />
              <CardImg
                top
                style={{ width: "100%", maxWidth: "450px" }}
                src="https://s3.us-east-2.amazonaws.com/app.broadlysocial.com/media/images/mia.webp"
                alt="Conversation Threading"
              />
            </Col>
            <Col md="8">
              <CardText>
                <strong>Description</strong>
                <br />
                This exercise is designed to practice the skill of continuously
                finding new topics for conversation, effectively preventing
                stale moments or conversational dead ends. Participants engage
                in a back-and-forth exchange where each reply introduces a new
                topic, inspired by a word or idea from the previous statement.
                <br /> <br />
                <strong>Instructions</strong>
                <ul>
                  <li>
                    No Fixed Topics: Start the conversation without any
                    predetermined topics.
                  </li>
                  <li>
                    Find Inspiration: Use a word or idea from your partner's
                    last statement as a springboard for a new topic.
                  </li>
                  <li>
                    Keep it Moving: The focus is on the fluid transition of
                    ideas, so direct responses or acknowledgments aren't
                    necessary.
                  </li>
                </ul>
                <strong>Example:</strong>
                <ul>
                  <li>
                    User: "I've been baking a lot lately, trying out new
                    recipes."
                  </li>
                  <li>
                    Mia: "Speaking of trying new things, I recently took up
                    online chess again. I used to play chess in high school."
                  </li>
                  <li>
                    User: "That reminds me, I heard my high school is having our
                    first class reunion next month. I'm really excited to see
                    all my old friends!"
                  </li>
                </ul>
                Participants continue to alternate, ensuring a seamless flow of
                unrelated topics.
              </CardText>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Row className="mt-3">
        <Col xs="6" className="d-flex justify-content-end pr-2">
          <Button style={buttonStyle} color="primary" onClick={previousPage}>
            Lesson 1: Getting Started
          </Button>
        </Col>
        <Col xs="6" className="d-flex justify-content-start pl-2">
          <Button style={buttonStyle} color="primary" onClick={nextPage}>
            Lesson 3: Connected Threads
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(ThreadingPage, {
  onRedirecting: () => <Loading />,
});
