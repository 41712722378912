import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardText,
  Button,
} from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import history from "../../utils/history";
import { AudioExerciseButton } from "../components/AudioInteraction";

const nextPage = () => {
  history.push("/exercises/discoveraffirm/learn");
};

const previousPage = () => {
  history.push("/exercises/connectedthread/learn");
};

export const WeavingPage = ({ jwtToken, handleTokenChange }) => {
  const buttonStyle = { width: "200px" };
  return (
    <Container className="mb-5">
      <Row>
        <Col md="8">
          <h1 className="mb-4">Exercise 4: Weaving Conversations</h1>
        </Col>
      </Row>

      <Card>
        <CardBody>
          <Row>
            <Col md="4">
              <AudioExerciseButton
                selectedExercise="weaving"
                jwtToken={jwtToken}
                onTokenChange={handleTokenChange}
              />
              <CardImg
                top
                style={{ width: "100%", maxWidth: "450px" }}
                src="https://s3.us-east-2.amazonaws.com/app.broadlysocial.com/media/images/mia.webp"
                alt="Conversation Threading"
              />
            </Col>
            <Col md="8">
              <CardText>
                <strong>Description</strong>
                <br />
                This exercise combines the skill of connected threading while
                navigating through a framework of questions. This is the first
                stage where conversations could become dynamic and lead to
                highly interesting discussions.
                <br /> <br />
                <strong>Questions</strong>
                <ol>
                  <li>What have you been up to today?</li>
                  <li>What part of town are you from?</li>
                  <li>What do you like to do for fun?</li>
                </ol>
                <br />
                <strong>Instructions</strong>
                <ul>
                  <li>
                    User: Ask Question 1: "What have you been up to today?"
                  </li>
                  <li>Mia: [answer]</li>
                  <li>
                    User: Acknowledge Mia's answer, thread briefly, then ask
                    Question 2: What part of town are you from?
                  </li>
                  <li>Mia: [answer] </li>
                  <li>
                    User: Acknowledge Mia's answer, thread briefly, then ask
                    Question 3: What do you like to do for fun?
                  </li>
                  <li>Mia: [answer] </li>
                </ul>
                <strong>Example:</strong>
                <ul>
                  <li>User: "What have you been up to today?"</li>
                  <li>
                    Mia: "I just finished organizing my workspace at home."
                  </li>
                  <li>
                    User: "That must feel really nice! It's made me think about
                    adding some greenery to my own desk area. What part of town
                    are you from?"
                  </li>
                  <li>
                    Mia: "I live in the suburbs but usually spend my days
                    downtown."
                  </li>
                  <li>
                    User: "That's great! I love it downtown, there are so many
                    fantastic restaurants to go to. So what do you like to do
                    for fun?"
                  </li>
                </ul>
              </CardText>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Row className="mt-3">
        <Col xs="6" className="d-flex justify-content-end pr-2">
          <Button style={buttonStyle} color="primary" onClick={previousPage}>
            Lesson 3: Connected Threads
          </Button>
        </Col>
        <Col xs="6" className="d-flex justify-content-start pl-2">
          <Button style={buttonStyle} color="primary" onClick={nextPage}>
            Lesson 4: Discover and Affirm
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(WeavingPage, {
  onRedirecting: () => <Loading />,
});
