import React, { useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardText,
  Button,
} from "reactstrap";
import history from "../../utils/history";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { AudioExerciseButton } from "../components/AudioInteraction";

export const GettingStartedPage = ({
  jwtToken,
  handleTokenChange,
  autoPlayAudioPreference,
}) => {
  const audioRef = useRef(null);

  useEffect(() => {
    const playAudio = async () => {
      await audioRef.current.play();
    };
    if (autoPlayAudioPreference === true) {
      playAudio();
    }
  }, [autoPlayAudioPreference]);

  const goToExercise = () => {
    history.push("/exercises/thread/learn");
  };

  return (
    <Container className="mb-5">
      <Row>
        <Col md="8">
          <h1 className="mb-4">Lesson 1: Getting Started</h1>
        </Col>
      </Row>

      <Card>
        <CardBody>
          <Row>
            <Col md="4">
              <AudioExerciseButton
                selectedExercise="getting-started"
                jwtToken={jwtToken}
                onTokenChange={handleTokenChange}
              />
              <CardImg
                top
                style={{ width: "100%", maxWidth: "450px" }}
                src="https://s3.us-east-2.amazonaws.com/app.broadlysocial.com/media/images/mia.webp"
                alt="Mia"
              />
              <CardText>
                <audio
                  src="https://s3.us-east-2.amazonaws.com/app.broadlysocial.com/media/audio/exercise-basic-intro-mia-instructions.mp3"
                  ref={audioRef}
                  controls
                  playsInline
                />
              </CardText>
            </Col>
            <Col md="8">
              <CardText>
                This is our first lesson - introducing yourself. To get started,
                let's cover how our practice conversations actually work.
                <br />
                <br />
                <ul>
                  <li>You'll see a button that reads, "Push to Talk".</li>
                  <li>
                    When you hold down the button, it's your turn to talk.
                  </li>
                  <li>
                    Once you release the button, I'll respond with what I have
                    to say.
                  </li>
                  <li>
                    Let's try it now with our first drill, which is just a basic
                    introduction.
                  </li>
                  <li>
                    You'll hold down the button, say hello and just introduce
                    yourself.
                  </li>
                </ul>
                <strong>Exercise - Getting Started:</strong>
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;You: Hi, I'm [name]. How's it going?
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;Mia: [Brief response]
              </CardText>
            </Col>
          </Row>
          <br />
          <br />
        </CardBody>
      </Card>
      <br />
      <Row className="mt-3">
        <Col xs="6" className="d-flex justify-content-end pr-2">
          <Button color="primary" onClick={goToExercise}>
            Lesson 2: Endless Ideas - Threading
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(GettingStartedPage, {
  onRedirecting: () => <Loading />,
});
