import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { ENDPOINT } from '../../constants';

export const Dashboard = ({token, onTokenChange}) => {
  console.log("Dashboard token")
  console.log(token)
  
  const { user, getAccessTokenSilently } = useAuth0();
  const [state, setState] = useState({
    token: token,
    error: null,
  });
  const [formData, setFormData] = useState({
    gender: '',
    seeking_gender: '',
  });
  
  
  useEffect(() => {
    const getToken = async () => {
      try {
        console.log(token)
        const response = await fetch(`${ENDPOINT}/heartbeat`, {
          method: 'GET'
        });
        
        if (token === 'asdf') {
          token = await getAccessTokenSilently();
          onTokenChange(token);
          console.log(token)
          // Upsert user to users table
          const response = await fetch(`${ENDPOINT}/user`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `${token}`,
            }
          });
          console.log(await response.json())
    
          
        }
      } catch (error) {
      }
    };
    getToken();
  }, [getAccessTokenSilently, state]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(`${ENDPOINT}/user/detail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
      body: JSON.stringify(formData),
    });
    console.log(await response.json());
  };
  
  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={2}>
          <img
            src={user.picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </Col>
        <Col md>
          <h2>{user.name}</h2>
          <p className="lead text-muted">{user.email}</p>
        </Col>
      </Row>
      <Row>
        <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
      </Row>
    </Container>
  );
  
};

export default withAuthenticationRequired(Dashboard, {
  onRedirecting: () => <Loading />,
});
