import React, { useState, useEffect } from "react";
import Card from 'react-bootstrap/Card';
import { Container, Row, Col, CardBody, CardFooter, Form, FormGroup, Label, Input, Button } from "reactstrap";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

const products = [
    {
      name: 'Product A',
      description: 'This is product A',
      price: '$10.00'
    },
    {
      name: 'Product B',
      description: 'This is product B',
      price: '$20.00'
    },
    {
      name: 'Product C',
      description: 'This is product C',
      price: '$30.00'
    }
  ];

export const ProductsPage = () => {
    return (
        <Container className="mb-5">
            <h1 className="text-center mb-5">Product Selector Page</h1>
            <Row>
            {products.map((product, index) => (
                <Col md={4} key={index}>
                <Card>
                    <CardBody>
                        <Card.Title>{product.name}</Card.Title>
                        <Card.Text>{product.description}</Card.Text>
                    </CardBody>
                    <CardFooter>
                        <span>{product.price}</span>
                        <Button className="float-right" color="primary">Select</Button>
                    </CardFooter>
                </Card>
                </Col>
            ))}
            </Row>
        </Container>
    );

};

export default withAuthenticationRequired(ProductsPage, {
    onRedirecting: () => <Loading />,
});
