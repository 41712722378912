import React from 'react';

function VideoPlayer(props) {
  return (
    <video controls style={{ width: '854px', height: '480px' }}>
      <source src={props.videoUrl} type="video/mp4" />
    </video>
  );
}

export default VideoPlayer;
