import React, { useState, useEffect } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from "jwt-decode";
import { ENDPOINT } from "../../constants";
import history from "../../utils/history";
const NavBar = ({ jwtToken, onTokenChange, setAutoPlayAudioPreference }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const {
    user,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      console.log("Development code");
    } else {
      console.log("Production code");
    }
    const getToken = async () => {
      try {
        if (jwtToken === "asdf") {
          jwtToken = await getAccessTokenSilently();
          onTokenChange(jwtToken);
          console.log(jwtToken);

          console.log("User info:");
          console.log(jwtToken);
          // Decode the token to get its payload

          // Upsert user to users table
          const response = await fetch(`${ENDPOINT}/user`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${jwtToken}`,
            },
            body: JSON.stringify({"hello":"sir"})
          });

          console.log("We have a response...");
          console.log("Navbar");
          if (response.ok) {
            
            const data = await response.json(); // This line reads and parses the JSON body
            console.log(JSON.stringify(data));
            let { userId, isFirstVisit, autoPlayAudio } = data.body;
            console.log("userId:", userId);
            console.log("isFirstVisit:", isFirstVisit);
            console.log("autoPlayAudio:", autoPlayAudio);
            setAutoPlayAudioPreference(autoPlayAudio);
            if (isFirstVisit) {
              history.push("/exercises/meet/mia");
            }
          } else {
            console.log("HTTP error:", response.status);
            const errorResponse = await response.text(); // For non-JSON responses or errors
            console.log(errorResponse);
          }

          const decodedToken = jwtDecode(jwtToken);
          console.log("Jwt decode complete");
          console.log(JSON.stringify(decodedToken));
          console.log(decodedToken);
          console.log("Is admin?");
          console.log(decodedToken["https://app.broadlysocial.com/isAdmin"]);
          if (decodedToken["https://app.broadlysocial.com/isAdmin"] === true) {
            setIsAdmin(true);
            console.log("User is admin");
          }
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };
    getToken();
    console.log("Execute getToken");
  }, [jwtToken]);

  const logoutWithRedirect = () => {
    let redirectUrl = "https://www.broadlysocial.com";
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      redirectUrl = "http://localhost:3000";
    }
    logout({
      logoutParams: {
        returnTo: redirectUrl,
      },
    });
  };
  return (
    <div className="nav-container">
      <Navbar color="light" light expand="md">
        <Container>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              {/* Always visible NavItem can go here, for example, Home */}
              {!isAuthenticated && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/"
                    exact
                    activeClassName="router-link-exact-active"
                  >
                    Home
                  </NavLink>
                </NavItem>
              )}

              {/* Conditionally rendered NavItem for authenticated users */}
              {isAuthenticated && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/home"
                    exact
                    activeClassName="router-link-exact-active"
                  >
                    Home
                  </NavLink>
                </NavItem>
              )}
              {isAuthenticated && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/exercises/meet/mia"
                    exact
                    activeClassName="router-link-exact-active"
                  >
                    Meet Mia
                  </NavLink>
                </NavItem>
              )}
              {isAuthenticated && isAdmin && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/sandbox"
                    exact
                    activeClassName="router-link-exact-active"
                  >
                    Sandbox
                  </NavLink>
                </NavItem>
              )}
            </Nav>

            <Nav className="ml-auto" navbar>
              {isAuthenticated ? (
                <>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret id="profileDropDown">
                      <img
                        src={user.picture}
                        alt="Profile"
                        className="nav-user-profile rounded-circle"
                        width="50"
                      />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem header>{user.name}</DropdownItem>
                      <DropdownItem
                        id="qsLogoutBtn"
                        onClick={logoutWithRedirect}
                      >
                        <FontAwesomeIcon icon="power-off" className="mr-3" />{" "}
                        Log out
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </>
              ) : (
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    className="btn-margin"
                    onClick={() => loginWithRedirect()}
                  >
                    Log in
                  </Button>
                </NavItem>
              )}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
