import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
} from "reactstrap";
import Loading from "../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import history from "../../utils/history";

export const MeetMiaPage = ({ jwtToken, handleTokenChange, autoPlayAudioPreference }) => {
  const audioRef = useRef(null);
  const goToExercises = () => {
    history.push("/exercises/start/learn");
  };

  useEffect(() => {
    const playAudio = async () => {
      await audioRef.current.play();
    }
    if (autoPlayAudioPreference === true) {
      playAudio();
    }
  }, [autoPlayAudioPreference]);
  //const history = useHistory();
  //https://s3.us-east-2.amazonaws.com/app.broadlysocial.com/media/audio/mia-intro.mp3
  return (
    <Container className="mb-5">
      <Row className="justify-content-center">
        <Col md="8">
          <h1 className="mb-4">Meet Mia: Your Conversational AI Companion</h1>
          <Card>
            <CardImg
              top
              width="100%"
              src="https://s3.us-east-2.amazonaws.com/app.broadlysocial.com/media/images/mia.webp"
              alt="Mia"
            />
            <div>
              <audio
                src="https://s3.us-east-2.amazonaws.com/app.broadlysocial.com/media/audio/mia-intro.mp3"
                controls
                ref={audioRef}
                playsInline
              />
            </div>
            <CardBody>
              <CardTitle tag="h5">
                Welcome to your interactive learning journey with Mia
              </CardTitle>
              <CardText>
                Mia, our advanced conversational AI, is designed to help you
                enhance your social skills through engaging and educational
                exercises. She can simulate real-life conversations, providing a
                safe and responsive environment for you to practice and develop
                your conversational abilities.
              </CardText>
              <CardText>
                <strong>Understanding Mia:</strong> Adaptive responses, role
                play capabilities, and feedback readiness are key features that
                make each interaction with Mia unique.
              </CardText>
              <CardText>
                <strong>Engaging with Mia:</strong> Feel free to start
                conversations, practice as much as you can, and sometimes,
                switch roles to experience different perspectives.
              </CardText>
              <CardText>
                <strong>What Mia Offers:</strong> A safe learning environment,
                diverse scenarios for practice, and the ability to move at your
                personalized pace.
              </CardText>
              <CardText>
                <strong>Tips for a Successful Session:</strong> Be open, reflect
                on your conversations, and use Mia's feedback to improve.
              </CardText>
              <CardText>
                Ready to begin your conversationa  practice with Mia? Let's
                embark on this journey together. Your path to becoming a more
                confident and skilled conversationalist starts now.
              </CardText>
              <Button color="primary" onClick={goToExercises}>
                Start Now
              </Button>{" "}
              {/* Add the button to navigate */}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(MeetMiaPage, {
  onRedirecting: () => <Loading />,
});
