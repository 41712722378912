import React, { useState, useRef, useEffect } from "react";
import { Container, Button, FormGroup, Label, Input } from "reactstrap";
import Loading from "../components/Loading";
import { ENDPOINT } from "../../constants";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

export const ExercisesPage = ({ jwtToken, onTokenChange }) => {
  const [selectedExercise, setExercise] = useState("threading");
  const [lastExercise, setLastExercise] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordTime, setRecordTime] = useState(0);
  const [audioUrl, setAudioUrl] = useState(null);
  const mediaRecorderRef = useRef(null);
  const intervalRef = useRef(null);
  const [sessionId, setSessionId] = useState(null);
  const { user, getAccessTokenSilently } = useAuth0();

  const startSessionIfNeeded = async () => {
    if (!sessionId || lastExercise !== selectedExercise) {
      console.log("Starting new session for:", selectedExercise);
      // Reset existing session if exercise has changed
      setSessionId(null);
      setLastExercise(selectedExercise); // Update lastExercise to the current one

      // Logic to start a new session...
      try {
        const response = await fetch(`${ENDPOINT}/exercise/session/`, {
          method: "POST",
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            exerciseName: selectedExercise,
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to start new exercise session");
        }
        const data = await response.json();
        setSessionId(data.sessionId); // Update session ID state
      } catch (error) {
        console.error("Error starting new session:", error);
      }
    }
  };

  const startRecording = async () => {
    if (isRecording) return;
    await startSessionIfNeeded();
    setIsRecording(true);
    setRecordTime(0);
    intervalRef.current = setInterval(() => {
      setRecordTime((prevTime) => prevTime + 1);
    }, 1000);

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: "video/webm",
      });
      let audioChunks = [];
      mediaRecorderRef.current.start();

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunks.push(event.data);
      };
      console.time("fetch-audio/upload");
      const presignedUrlResponse = await fetch(`${ENDPOINT}/audio/upload`, {
        method: "POST",
        headers: {
          Authorization: `${jwtToken}`, // Assuming JWT token is needed for auth
        },
        body: JSON.stringify({
          exerciseName: selectedExercise,
          sessionId,
        }),
      });
      console.timeEnd("fetch-audio/upload");
      if (!presignedUrlResponse.ok) {
        throw new Error("Failed to get presigned URL");
      }
      let responseData = await presignedUrlResponse.json();
      let uploadData = JSON.parse(responseData.body);
      const { presignedUrl, audioObjectKey, exerciseId } = uploadData;
      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunks, { type: "video/webm" });

        //console.log("Audio URL:", audioUrl);
        //setAudioUrl(audioUrl);
        console.time("start-audio");
        await uploadAudio(audioBlob, presignedUrl, audioObjectKey, exerciseId); // Upload the audioBlob directly after recording stops
        console.timeEnd("start-audio");
      };
    } catch (err) {
      console.error("Error accessing media devices.", err);
      setIsRecording(false);
    }
  };

  const stopRecording = () => {
    if (!isRecording) return;
    setIsRecording(false);
    clearInterval(intervalRef.current);
    intervalRef.current = null;

    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop(); // This triggers the onstop event
    }
  };

  const uploadAudio = async (
    audioBlob,
    preSignedUrl,
    audioObjectKey,
    exerciseId
  ) => {
    try {
      // Step 1: Start a threading session

      // Step : Request a presigned URL from your backend

      // Step : Upload the file to S3 using the presigned URL
      const formData = new FormData();
      Object.entries(preSignedUrl.fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("file", audioBlob);

      console.time("upload-s3");
      const s3Response = await fetch(preSignedUrl.url, {
        method: "POST",
        body: formData, // FormData will set the correct content type header
      });
      console.timeEnd("upload-s3");
      if (!s3Response.ok) {
        throw new Error("Failed to upload audio to S3");
      }

      // Step: POST to the transcribe/audio endpoint
      // Step: POST to the transcribe/audio endpoint
      console.time("fetch-transcribe/audio");
      const transcribeResponse = await fetch(
        `${ENDPOINT}/transcription/audio/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Ensure you're setting the correct content type
            Authorization: `${jwtToken}`, // Include other necessary headers like authorization if needed
            // Include other necessary headers like authorization if needed
          },
          body: JSON.stringify({
            exerciseName: selectedExercise,
            exerciseId,
            audioObjectKey,
            sessionId,
          }),
        }
      );
      console.timeEnd("fetch-transcribe/audio");
      if (transcribeResponse.ok) {
        const responseBody = await transcribeResponse.json(); // Assuming the response is JSON
        const audioDownloadLink = responseBody.audioDownloadLink;

        // Set the audio URL state to the download link
        setAudioUrl(audioDownloadLink);
      } else {
        console.log(
          "Failed to fetch:",
          transcribeResponse.status,
          transcribeResponse.statusText
        );
        const errorBody = await transcribeResponse.text(); // Handle error case
        console.log("Error response body:", errorBody);
      }

      // Optionally, set state or perform additional actions after successful upload
    } catch (error) {
      console.error("Error during the upload process:", error);
    }
  };

  useEffect(() => {
    const startSession = async () => {
      try {
        const response = await fetch(`${ENDPOINT}/heartbeat`, {
          method: "GET",
        });
        if (jwtToken === "asdf") {
          console.log("Getting access token on DrilLThreadingAI");
          jwtToken = await getAccessTokenSilently();
          onTokenChange(jwtToken);
          console.log(jwtToken);
          // Upsert user to users table
          const response = await fetch(`${ENDPOINT}/user`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${jwtToken}`,
            },
          });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        return; // Return early if there's an error getting the token
      }
    };
    console.log("Execute getToken");
    startSession();

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stream
          .getTracks()
          .forEach((track) => track.stop());
      }
    };
  }, [jwtToken]);

  const handleSkillChange = (e) => {
    setExercise(e.target.value);
  };

  return (
    <Container className="mb-5">
      <FormGroup>
        <Label for="skillSelect">Select a Skill</Label>
        <Input
          type="select"
          name="select"
          id="skillSelect"
          onChange={handleSkillChange}
          value={selectedExercise}
        >
          <option value="threading">threading</option>
          <option value="general">general</option>
          <option value="salesperson">salesperson</option>
          <option value="cold-read">cold-read</option>
          <option value="intro">intro</option>
          <option value="mini-weave">mini-weave</option>
        </Input>
      </FormGroup>
      <div>
        <Button
          color="primary"
          onMouseDown={startRecording}
          onMouseUp={stopRecording}
          onMouseLeave={stopRecording}
          onTouchStart={startRecording}
          onTouchEnd={stopRecording}
        >
          {isRecording ? "Recording..." : "Hold to Record"}
        </Button>
        <div>Recording Time: {recordTime} seconds</div>
        {audioUrl && (
          <div>
            <audio src={audioUrl} controls autoPlay />
            {/* Optionally, add a button or automatic call to uploadAudio(audioBlob) here if needed */}
          </div>
        )}
      </div>
    </Container>
  );
};

export default withAuthenticationRequired(ExercisesPage, {
  onRedirecting: () => <Loading />,
});
