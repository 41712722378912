import React, { useEffect} from "react";
import { Button, Container, Row, Col } from "reactstrap";
import history from "../../utils/history";
import { ENDPOINT } from "../../constants";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

export const Welcome = ({ jwtToken, onTokenChange }) => {
  const { user, getAccessTokenSilently } = useAuth0();

  // Function to navigate to the threading exercise
  const goToThreadingExercise = () => {
    // Assuming the route to the threading exercise is '/threading'
    history.push("/exercises/threading/learn");
  };

  useEffect(() => {
    const getToken = async () => {
      try {
        if (jwtToken === "asdf") {
          jwtToken = await getAccessTokenSilently();
          onTokenChange(jwtToken);
          console.log(jwtToken);
          // Upsert user to users table
          const response = await fetch(`${ENDPOINT}/user`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${jwtToken}`,
            },
          });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        return; // Return early if there's an error getting the token
      }
    };
    getToken();
    console.log("Execute getToken");

  }, [jwtToken]);

  return (
    <Container className="text-center mt-5">
      <Row>
        <Col>
          <h1>Welcome to Broadly Social!</h1>
          <p className="mt-4">
            Dive into the art of social dynamics with BroadlySocial, your go-to platform for mastering social skills. 
            From making a great first impression to mastering the art of conversation, we provide comprehensive training to enhance your social fluency.
          </p>
          <p>
            Begin your journey with our foundational exercise on <strong>threading</strong> — a technique that will transform the way you engage in conversations.
            Discover the power of effective communication, build confidence, and connect with others like never before.
          </p>
          <Button color="primary" onClick={goToThreadingExercise} className="mt-3">
            Learn: Threading
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(Welcome, {
  onRedirecting: () => <Loading />,
});
