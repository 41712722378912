import React, { useEffect, useState } from 'react';
import { Drawer, List, ListItem, ListItemText, Accordion, AccordionSummary, AccordionDetails, Typography, Button, Box, Container } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import history from '../../utils/history';

export const Home = ({ jwtToken, onTokenChange }) => {
  const [lessonStatus, setLessonStatus] = useState({
    lesson0: false,
    lesson1: false,
    lesson2: false,
    lesson3: false,
    lesson4: false,
  });

  const [openAccordion, setOpenAccordion] = useState(''); // State for controlling accordion

  useEffect(() => {
    // Simulated fetch from API
    async function fetchLessonStatus() {
      const mockApiData = {
        // Simulated response data
        lesson0: true,
        lesson1: true, // Example: User has completed Lesson 1
        lesson2: false,
        lesson3: false,
        lesson4: false,
      };
      setLessonStatus(mockApiData);
    }
    fetchLessonStatus();
  }, []);

  const toggleAccordion = (id) => {
    setOpenAccordion(openAccordion === id ? '' : id); // Toggle accordion state
  };

  // Function to navigate to the threading exercise
  const goToLesson = (path) => {
    history.push(path);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Sidebar */}
      <Drawer variant="permanent" anchor="left" sx={{ width: 240, flexShrink: 0 }}>
        <Box sx={{ width: 240, padding: 2 }}>
          <Typography variant="h6" noWrap component="div">
            Lessons
          </Typography>
          <List>
            <Accordion expanded={openAccordion === '1'} onChange={() => toggleAccordion('1')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Getting Started</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ListItem button onClick={() => goToLesson("/exercises/start/learn")}>
                  <ListItemText primary="Lesson" />
                </ListItem>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={openAccordion === '2'} onChange={() => toggleAccordion('2')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Threading</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ListItem button onClick={() => goToLesson("/exercises/thread/learn")}>
                  <ListItemText primary="Lesson" />
                </ListItem>
                <ListItem button onClick={() => goToLesson("/exercises/thread/practice")}>
                  <ListItemText primary="Practice" />
                </ListItem>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={openAccordion === '3'} onChange={() => toggleAccordion('3')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Weaving</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ListItem button onClick={() => goToLesson("/exercises/weave/learn")}>
                  <ListItemText primary="Lesson" />
                </ListItem>
                <ListItem button onClick={() => goToLesson("/exercises/weave/practice")}>
                  <ListItemText primary="Practice" />
                </ListItem>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={openAccordion === '4'} onChange={() => toggleAccordion('4')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Cold Read</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ListItem button onClick={() => goToLesson("/exercises/coldread/learn")}>
                  <ListItemText primary="Lesson" />
                </ListItem>
                <ListItem button onClick={() => goToLesson("/exercises/coldread/practice")}>
                  <ListItemText primary="Practice" />
                </ListItem>
              </AccordionDetails>
            </Accordion>
          </List>
        </Box>
      </Drawer>

      {/* Content Area */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container>
          <Typography variant="h4" gutterBottom>
            Welcome back!
          </Typography>
          <Typography variant="body1" paragraph>
            Dive into the art of social dynamics with BroadlySocial, your go-to platform for mastering social skills. From making a great first impression to mastering the art of conversation, we provide comprehensive training to enhance your social fluency.
          </Typography>
          <Typography variant="body1" paragraph>
            Begin your journey with our foundational exercise on <strong>threading</strong> — a technique that will transform the way you engage in conversations. Discover the power of effective communication, build confidence, and connect with others like never before.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default Home;
